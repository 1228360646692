@import "bootstrap/dist/css/bootstrap.min.css";
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700&display=swap');

* {
   font-family: "Roboto Condensed", sans-serif;
   margin: 0;
   padding: 0;
   box-sizing: border-box;
   
}

body {
   background-color: hwb(0 100% 0% / 0.205);
}

.map{
   width: 80%;
   height: 300px;
   margin: 30px auto;
   background: #999;
}

.map iframe {
   width: 100%;
   height: 100%;
   border: 0;
}
